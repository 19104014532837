var $packery = $('.js-packery').packery({
    itemSelector: '.js-packeryItem'
});

$(window).on('pageshow',function(){
    $('body').addClass('is-loaded');

    $packery.packery('layout');

    //screensaver();
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

$('.js-toggleMenu').on('click',function(){
    $('body').toggleClass('is-state--menu');
});

$('.js-cookieConsent').on('click',function(){
    $('body').addClass('has-consent');
    Cookies.set('bl_consent', 'true');
});

$('.js-team').on('click',function(){
    $('body').toggleClass('is-state--menu');
});

$('.js-planThumbnail').on('click',function(e){
    e.preventDefault();
    $('.js-plan').show().addClass('is-active').find('img').attr('loading','eager');
    var href = $(this).attr('data-href');
    setTimeout(function(){
        var scroll = $('.js-slides').scrollTop(),
            top = $('#'+href)[0].getBoundingClientRect().top;
        $('.js-slides').scrollTop(scroll+top);
    }, 200);
    e.stopPropagation();
});

if ( $('.js-heroSlides').length ) {

    var slides_index = 0,
        slides_total = $('.js-heroSlide').length;

    setInterval(function(){

        if ( slides_index == 0 ) {
            $('.js-heroSlide').removeClass('is-passed');
        } else {
            $('.js-heroSlide:eq('+(slides_index-1)+')').removeClass('is-passed');
        }

        $('.js-heroSlide:eq('+slides_index+')').removeClass('is-active');
        $('.js-heroSlide:eq('+slides_index+')').addClass('is-passed');

        slides_index++;

        if ( slides_index == slides_total ) {
            slides_index = 0;
        }

        $('.js-heroSlide:eq('+slides_index+')').addClass('is-active');

        var graphic = $('.js-heroSlide:eq('+slides_index+')').find('.Hero-graphic'),
            footer = $('.Footer');

        footer.removeClass('Footer--green Footer--yellow Footer--red Footer--blue');

        if ( graphic.hasClass('Hero-graphic--green') ) {
            footer.addClass('Footer--green');
        } else if ( graphic.hasClass('Hero-graphic--yellow') ) {
            footer.addClass('Footer--yellow');
        } else if ( graphic.hasClass('Hero-graphic--red') ) {
            footer.addClass('Footer--red');
        } else if ( graphic.hasClass('Hero-graphic--blue') ) {
            footer.addClass('Footer--blue');
        }

    }, 6000);

}

if ( $('.js-index').length ) {

    $('.js-indexSort').on('click',function(e){
        let el = e.target,
            sortby = el.dataset.sort,
            order = el.dataset.order,
            index = $(this).index(),
            $list = $('.js-indexList');
        $('.js-index').attr('data-index', (index+1));
        if ( order != 'DESC' ) {
            order = 'DESC';
        } else {
            order = 'ASC';
        }
        if ( sortby == 'name' ) {
            if ( order == 'ASC' ) {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return a.dataset.name.localeCompare(b.dataset.name);
                }).appendTo($list);
            } else {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return b.dataset.name.localeCompare(a.dataset.name);
                }).appendTo($list);
            }
        } else if ( sortby == 'type' ) {
            if ( order == 'ASC' ) {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return a.dataset.type.localeCompare(b.dataset.type);
                }).appendTo($list);
            } else {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return b.dataset.type.localeCompare(a.dataset.type);
                }).appendTo($list);
            }
        } else if ( sortby == 'size' ) {
            if ( order == 'ASC' ) {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return +a.dataset.size - +b.dataset.size;
                }).appendTo($list);
            } else {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return +b.dataset.size - +a.dataset.size;
                }).appendTo($list);
            }
        } else if ( sortby == 'year' ) {
            if ( order == 'ASC' ) {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return +a.dataset.year - +b.dataset.year;
                }).appendTo($list);
            } else {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return +b.dataset.year - +a.dataset.year;
                }).appendTo($list);
            }
        } else if ( sortby == 'status' ) {
            if ( order == 'ASC' ) {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return a.dataset.status.localeCompare(b.dataset.status);
                }).appendTo($list);
            } else {
                $list.find('.js-indexEntry').sort(function(a, b) {
                    return b.dataset.status.localeCompare(a.dataset.status);
                }).appendTo($list);
            }
        }
        $('.js-indexSort').removeClass('is-active');
        el.classList.add('is-active');
        el.dataset.order = order;
    });

    $('.js-indexSort:first-child').click();

    $('.js-searchField').on('focus', function(){
        $(this).attr('placeholder','Type to search...');
        $('.js-search').addClass('is-active');
    });

    $('.js-searchField').on('keyup', function(){
        var search = $(this).val().toLowerCase();
        if ( search.length < 4 ) {
            $('.js-indexEntry').each(function(){ 
                $(this).show();
            });
            $('.js-searchOption').each(function(){ 
                $(this).show();
            });
        } else {
            $('.js-indexEntry').each(function(){
                $(this).hide();
                if( $(this).text().toLowerCase().match(search) ) {
                    $(this).show();   
                }
            });
            $('.js-searchOption').each(function(){
                $(this).hide();
                if( $(this).text().toLowerCase().match(search) ) {
                    $(this).show();   
                }
            });
        }
    });

    $('.js-searchOption').on('click', function(e){
        var search = $(this).val().toLowerCase();
        $('.js-indexEntry').each(function(){
            $(this).hide();
            if( $(this).text().toLowerCase().match(search) ) {
                $(this).show();   
            }
        });
        $('.js-searchField').attr('placeholder','('+$(this).val()+' ×)');
        $('.js-searchField').val('('+$(this).val()+' ×)');
    });

    $('.js-searchField').on('blur', function(){
        setTimeout(function(){
            $('.js-search').removeClass('is-active');
            $('.js-searchOption').each(function(){ 
                $(this).show();
            });
            if ( $('.js-searchField').val() ) {
                $('.js-searchField').val('');
            } else {
                $('.js-searchField').attr('placeholder','Search...');
                $('.js-indexEntry').each(function(){ 
                    $(this).show();
                });
            }
        }, 200);
    });

}

// document.getElementById("sortBy").addEventListener("click", dropDown);
// function dropDown() {
//     sortText.innerHTML = "SORT BY:";
//     document.querySelector(".sortable").classList.toggle("opacity");
//     document.getElementById("dropdown").classList.toggle("show");
// }
// window.onclick = function (event) {
//     if (!event.target.matches(".drop-button")) {
//         let dropdowns = document.getElementsByClassName("dropdown-content");
//         let i;
//         for (i = 0; i < dropdowns.length; i++) {
//             let openDropdown = dropdowns[i];
//             if (openDropdown.classList.contains("show")) {
//                 openDropdown.classList.remove("show");
//                 document.querySelector(".sortable").classList.remove("opacity");
//             }
//         }
//     }
// };

// let sortText = document.getElementById("sortBy");

// document.getElementById("js-dropdownName").onclick = function () {
//     document.getElementById("name").click();
//     sortText.innerHTML = "SORT BY: NAME";
// };
// document.getElementById("js-dropdownType").onclick = function () {
//     document.getElementById("type").click();
//     sortText.innerHTML = "SORT BY: TYPE";
// };
// document.getElementById("js-dropdownSize").onclick = function () {
//     document.getElementById("size").click();
//     sortText.innerHTML = "SORT BY: SIZE";
// };
// document.getElementById("js-dropdownYear").onclick = function () {
//     document.getElementById("year").click();
//     sortText.innerHTML = "SORT BY: YEAR";
// };
// document.getElementById("js-dropdownStatus").onclick = function () {
//     document.getElementById("status").click();
//     sortText.innerHTML = "SORT BY: STATUS";
// };

// // DESKTOP SORT

// document.addEventListener("click", function (e) {
//     try {
//         function findElementRecursive(element, tag) {
//         return element.nodeName === tag
//             ? element
//             : findElementRecursive(element.parentNode, tag);
//         }

//         var descending_th_class = " dir-d ";
//         var ascending_th_class = " dir-u ";
//         var ascending_table_sort_class = "asc";
//         var regex_dir = / dir-(u|d) /;
//         var regex_table = /\bsortable\b/;
//         var alt_sort = e.shiftKey || e.altKey;
//         var element = findElementRecursive(e.target, "TH");
//         var tr = findElementRecursive(element, "TR");
//         var table = findElementRecursive(tr, "TABLE");

//         function reClassify(element, dir) {
//             element.className = element.className.replace(regex_dir, "") + dir;
//         }

//         function getValue(element) {
//             var value =
//             (alt_sort && element.getAttribute("data-sort-alt")) ||
//             element.getAttribute("data-sort") ||
//             element.innerText;
//             return value;
//         }

//         if (regex_table.test(table.className)) {
//             var column_index;
//             var nodes = tr.cells;

//             for (var i = 0; i < nodes.length; i++) {
//                 if (nodes[i] === element) {
//                 column_index = element.getAttribute("data-sort-col") || i;
//                 } else {
//                 reClassify(nodes[i], "");
//                 }
//             }

//             var dir = descending_th_class;

//             if (
//                 element.className.indexOf(descending_th_class) !== -1 ||
//                 (table.className.indexOf(ascending_table_sort_class) !== -1 &&
//                 element.className.indexOf(ascending_th_class) == -1)
//             ) {
//                 dir = ascending_th_class;
//             }

//             reClassify(element, dir);

//             for (var i = 0; i < table.tBodies.length; i++) {
//                 const org_tbody = table.tBodies[i];

//                 var rows = [].slice.call(org_tbody.rows, 0);

//                 var reverse = dir === ascending_th_class;

//                 rows.sort(function (a, b) {
//                 var x = getValue((reverse ? a : b).cells[column_index]);
//                 var y = getValue((reverse ? b : a).cells[column_index]);
//                 var bool =
//                     x.length && y.length && !isNaN(x - y) ? x - y : x.localeCompare(y);
//                 return bool;
//                 });

//                 var clone_tbody = org_tbody.cloneNode();

//                 while (rows.length) {
//                 clone_tbody.appendChild(rows.splice(0, 1)[0]);
//                 }

//                 table.replaceChild(clone_tbody, org_tbody);
//             }
//         }
//     } catch (error) {}
// });

// // SEARCH

// function searchTable() {
//     var input, filter, found, table, tr, td, i, j;
//     input = document.getElementById("searchInput");
//     filter = input.value.toUpperCase();
//     table = document.getElementById("myTable");
//     tr = table.getElementsByTagName("tr");
//     for (i = 0; i < tr.length; i++) {
//         td = tr[i].getElementsByTagName("td");
//         for (j = 0; j < td.length; j++) {
//         if (
//             td[j].innerHTML.toUpperCase().indexOf(filter) > -1 &&
//             input.value.length > 2
//         ) {
//             found = true;
//         }
//         }
//         if (found) {
//         tr[i].className = "js-tr-show";
//         found = false;
//         } else if (input.value.length < 3) {
//         tr[i].className = "js-tr-show";
//         } else {
//         tr[i].className = "js-hide";
//         }
//     }
// }
// document.getElementById("searchInput").onkeyup = function () {
//     searchTable();
// };

// document.getElementById("searchInput").addEventListener("keypress", (event) => {
//     if (event.key === "Enter") {
//         browsers.className = "js-hide";
//     }
// });

// // SEARCH SUGGESTIONS CLICK

// document.body.addEventListener("click", (e) => {
//     if (e.target.classList.contains("js-searchInput")) {
//         searchInput.value = "";
//         currentFocus = -1;
//         browsers.className = "js-show";
//     } else if (e.target.classList.contains("js-searchOption")) {
//         searchInput.value = e.target.value;
//         browsers.className = "js-hide";
//         searchTable();
//     } else if (browsers.className == "js-show") {
//         browsers.className = "js-hide";
//     }
// });

// var currentFocus = -1;

// // SEARCH SUGGESTIONS ARROWS

// searchInput.onkeydown = function (e) {
//     if (e.keyCode == 40) {
//         currentFocus++;
//         addActive(browsers.options);
//     } else if (e.keyCode == 38) {
//         currentFocus--;
//         addActive(browsers.options);
//     } else if (e.keyCode == 13) {
//         if (currentFocus > -1) {
//             if (browsers.options) browsers.options[currentFocus].click();
//             removeActive(browsers.options);
//         }
//     }
// };

// function addActive(x) {
//     if (!x) return false;
//     removeActive(x);
//     if (currentFocus >= x.length) currentFocus = 0;
//     if (currentFocus < 0) currentFocus = x.length - 1;
//     x[currentFocus].classList.add("active");
// }

// function removeActive(x) {
//     for (var i = 0; i < x.length; i++) {
//         x[i].classList.remove("active");
//     }
// }
